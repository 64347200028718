@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';

.glide__bullet {
  border: 1px solid #F5F5EC;
}

.glide__bullet--active {
  background: #F5F5EC;
}

/* Saol Text Book */
@font-face {
  font-family: 'SaolText';
  font-style: normal;
  font-weight: 350;
  src: local(''),
       url('../fonts/SaolText-Book.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/SaolText-Book.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}





:root {
  --gutter: 1.25rem;
  --screen-to-grid: calc((100vw - var(--container-width)) / 2);
  --aspect-ration-pricing-image: calc(3/2);
}

@media (min-width: 640px) {
  :root {
    --container-width: 640px;
  }
}

@media (min-width: 768px) {
  :root {
    --container-width: 768px;
  }
}

@media (min-width: 1024px) {
  :root {
    --container-width: 1024px;
  }
}

@media (min-width: 1280px) {
  :root {
    --container-width: 1280px;
  }
}

@media (min-width: 1536px) {
  :root {
    --container-width: 1536px;
  }
}

.w-image-edge-4cols {
  width: calc(var(--screen-to-grid) + ((var(--container-width) + var(--gutter)) / 3 - var(--gutter)));
}

.w-image-edge-5cols {
  width: calc(var(--screen-to-grid) + ((var(--container-width) + var(--gutter)) / 12 * 5 - var(--gutter)));
}

.min-h-accordion {
  min-height: calc((var(--screen-to-grid) + ((var(--container-width) + var(--gutter)) / 12 * 5 - var(--gutter))) * var(--aspect-ration-pricing-image) - 3.75rem );
}

@media (min-width: 1536px) {
  .min-h-accordion {
    min-height: 0;
  }
}

.text-image {
  margin-top: 8rem;
}

.text-image + .text-image {
  margin-top: 0;
}

.text-image-copy p {
  margin-bottom: 0.75rem;
}


.pricing-features li {
  margin-top: 0.75rem;
}

.teaser-image-full {
  width: 100vw;
  height: 100vw;
  margin-top: -1.25rem;
}

@media (min-width: 450px) {
  .teaser-image-full {
    height: 60vw;
  }
}

@media (min-width: 768px) {
  .teaser-image-full {
    height: 44vw;
    margin-top: 0;
  }
}

@media (min-width: 1536px) {
  .teaser-image-full {
    height: 600px;
  }
}

.teased-features a {
  border-bottom: 2px solid;
  padding-bottom: 0.125rem;
}

.teased-features a:hover {
  border-bottom: 2px solid transparent;
}